.home-list-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &-main {
    margin: auto;

    &-text {
      width: 300px;
      margin-bottom: 20px;
    }
  }
}
.home-list-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  &-spark {
    border: 2px ridge var(--ion-color-light-tint);
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
    opacity: .5;
    width: 100px;
    &-spinner {
      top: 0px !important;
      position: relative !important;
    }
  }
  &-col1 {
    flex: 1;
    text-align: center;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    font-size: .8em;
    color: var(--ion-color-medium-tint);

    &-row1, &-row2, &-row3 {
      display: flex;
      flex-direction: row;
    }
    
    &-row1 {
      font-size: 1.2em;
      font-weight: medium;
    }
    &-row2 {
      font-size: .8em;
    }
    &-row3 {
      font-size: .8em;
    }
    &-row4 {
      font-size: .8em;
    }

    div {
      flex-direction: row;
      white-space: nowrap;
    }
  }

  &-col2, &-col3 {
    flex: 0 auto;
    min-width: 70px;
    text-align: right;
    margin-right: 20px;
    font-size: .8em;
    color: var(--ion-color-medium-tint);
    div {
      flex-direction: row;
      white-space: nowrap;
    }
  }
  
  &-col4 {
    flex: 0 auto;
    text-align: right;
    margin-right: 20px;
    font-size: .8em;
    color: var(--ion-color-medium-tint);
    div {
      flex-direction: row;
      white-space: nowrap;
    }
  }

  &-value {
    text-align: right;
    font-weight: normal;
    font-size: 1.3em;
    &-suffix {
      font-weight: normal;
      font-size: .8em !important;
      display: none;
    }
    position: absolute;
    width: 120px;
    text-align: center;
    opacity: .8;
    ion-icon {
      color: var(--ion-color-warning);
    }
  }
}
