.company-content {
    display: flex;
  
    form {
      padding: 15px;
      min-width: 300px;
      max-width: 600px;
      margin: auto;
    }
  
    ion-header {
      padding: 10px 0px 10px 0px;
      border: none;
      &::after {
        background-image: unset;
      }
      ion-buttons {
        padding-right: 15px;
      }
    }
  
    .monitor-item {
      ion-button {
        text-transform: none;      
        font-size: .9em;
        font-weight: normal;
        &:hover,&:active {
          text-decoration: underline;
        }
      }
      ion-icon {
        cursor: pointer;
      }
    }
  
    ion-item {    
      ion-label {
        font-size: .8em !important;
        min-width: 110px;
        width: 100px;
        flex: 0 0 auto;
      }
  
      ion-input,
      ion-select {
        font-size: .8em;
        background-color: var(--ion-color-light-tint);
        width: 100%;
        flex: 0 0 auto;
        padding: 5px;
        
        .native-input {
          padding: 0px;
          margin: 5px;
        }
      }
    }
  }