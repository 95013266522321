.register-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .button-bar {
        width: 100%;
        text-align: center;

        ion-button {
            margin: 10px;
        }
    }

    &-main {
        margin: auto;
        text-align: left;

        &-text {
            min-width: 300px;
            max-width: 800px;
            margin: 20px;
        }

        &-body {
            width: 100%;
            min-width: 300px;
            max-width: 800px;
            margin: auto;
            text-align: center;

            ion-list {
                ion-item {
                    margin: 20px;

                    .native-input {
                        margin: 0px 10px 0px 10px;
                        padding: 5px;
                    }

                    .header {
                        font-weight: bold;
                        font-size: 1.2em;
                        margin: 10px 0px 10px 0px;
                    }

                    .subheader {
                        font-style: italic;
                        font-size: 1.1em;
                        margin-bottom: 10px;
                    }

                    ion-label {
                        padding-right: 10px;
                    }

                    .read-only-input {
                        padding: 0px 0px 0px 15px !important;
                    }

                    ion-input, ion-select {
                        margin: 0px 10px 5px 10px;
                        padding: 10px;
                        background-color: var(--ion-color-light-tint);
                    }

                    ion-select {
                        margin: 0px 10px 5px 10px;
                        padding: 5px 15px;
                        background-color: var(--ion-color-light-tint);
                    }

                    ion-item {
                        width: 100%;
                        margin: 5px;
                        padding: 0px;
                        font-size: .8em;
                        .item-native {
                            --padding-inline-start: 0px;
                        }
                    }
                }
            }
        }
    }
}