.facebook-signin {
    display: inline-block;
    background: white;
    color: #444;
    width: 208px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
    &:active {
        opacity: .7;
    }

    &-icon {
        background: url("data:image/svg+xml;utf8,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1365.3333 1365.3333' height='24' width='24' xml:space='preserve' id='svg2' version='1.1'%3E%3Cmetadata id='metadata8'%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:format%3Eimage/svg+xml%3C/dc:format%3E%3Cdc:type rdf:resource='http://purl.org/dc/dcmitype/StillImage' /%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3Cdefs id='defs6' /%3E%3Cg transform='matrix(1.3333333,0,0,-1.3333333,0,1365.3333)' id='g10'%3E%3Cg transform='scale(0.1)' id='g12'%3E%3Cpath id='path14' style='fill:%231877f2;fill-opacity:1;fill-rule:nonzero;stroke:none' d='m 10240,5120 c 0,2827.7 -2292.3,5120 -5120,5120 C 2292.3,10240 0,7947.7 0,5120 0,2564.46 1872.31,446.301 4320,62.1992 V 3640 H 3020 v 1480 h 1300 v 1128 c 0,1283.2 764.38,1992 1933.9,1992 560.17,0 1146.1,-100 1146.1,-100 V 6880 H 6754.38 C 6118.35,6880 5920,6485.33 5920,6080.43 V 5120 H 7340 L 7113,3640 H 5920 V 62.1992 C 8367.69,446.301 10240,2564.46 10240,5120' /%3E%3Cpath id='path16' style='fill:%23ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none' d='m 7113,3640 227,1480 H 5920 v 960.43 c 0,404.9 198.35,799.57 834.38,799.57 H 7400 v 1260 c 0,0 -585.93,100 -1146.1,100 C 5084.38,8240 4320,7531.2 4320,6248 V 5120 H 3020 V 3640 H 4320 V 62.1992 C 4580.67,21.3008 4847.84,0 5120,0 c 272.16,0 539.33,21.3008 800,62.1992 V 3640 h 1193' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E") transparent 0px 50% no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }

    &-text {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0px;
        padding-right: 8px;
        font-size: 14px;
        font-weight: bold;
        /* Use the Roboto font that is loaded in the <head> */
        font-family: 'Roboto', sans-serif;
        color: var(--ion-color-medium-shade);
    }
}