/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #004384;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #002364;
  --ion-color-primary-tint: #0063A4;

  /** secondary **/
  --ion-color-secondary: #ed1c24;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #cd0004;
  --ion-color-secondary-tint: #fd3c44;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #e0b500;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #d33939;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** light **/
  --ion-color-light: #222428;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 244, 244;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-background-color: #222222;
  --ion-toolbar-background: #333333;
  --ion-toolbar-color: var(--ion-color-dark-shade);
  --ion-text-color: var(--ion-color-dark-shade);
  --ion-text-color-rgb: 215, 216, 218;
  --color: var(--ion-color-dark-shade);

  .button-solid, .button-native, ion-button {
    --background: var(--ion-color-medium);
    --background-focused: var(--ion-color-medium-shade);
    --background-hover: var(--ion-color-medium-tint);
  }

  ion-item {
    --background-focused: var(--ion-background-color);
    --highlight-background: var(--ion-background-color);
    --min-height: 36px;
    --ion-item-border-color: var(--ion-background-color);
  }

  ion-alert {
    --ion-overlay-background-color: var(--ion-color-light);
  }

  .item-group-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    ion-buttons {
      padding-left: 20px;
      ion-button {
        height: 24px;
        ion-icon {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    ion-label {
      font-size: .75em !important;
    }
  }

  ion-router-link {
    --color: var(--ion-color-secondary-tint);
  }
  .alert-button {
    --ion-color-primary: var(--ion-color-secondary-tint);
  }

  ion-loading {
    --background: var(--ion-color-light);
    --spinner-color: var(--ion-color-medium-tint);
    color: var(--ion-color-medium-tint);
  }	
}

.content-dialog {
  ion-list {
    margin: 15px;
    min-width: 300px;
    max-width: 600px;
    margin: auto;
  }

  ion-header {
    padding: 15px;

    &::after {
      background: none;
    }
  }

  ion-item {
    display: flex;

    ion-label {
      font-size: .8em !important;
      width: 100px;
      flex: 0 0 auto;
    }

    ion-input,
    ion-select {
      font-size: .8em;
      background-color: var(--ion-color-light-tint);
      flex: 1 0 auto;
      padding: 5px;

      .native-input {
        padding: 0px;
        margin: 5px;
      }
    }
  }

  ion-buttons {
    margin: 10px;
  }

  .button-solid {
    --color: var(--ion-color-dark);
  }
}

.ion-page {
  ion-header {
    ion-toolbar {
      padding-right: 10px;

      ion-title {
        font-weight: normal;
        font-size: medium;
        text-align: center;
      }

      ion-back-button {
        color: var(--ion-color-medium-tint);
        height: 14px;
        width: 14px;
        margin-left: 12px;
      }
    }
  }

  ion-menu-toggle {
    ion-item {
      font-size: .9em;
    }
  }
}

.button-clear {
  --background: transparent !important;
  --background-hover: var(--background) !important;
  --background-focused: var(--background) !important;
  --background-active: var(--background) !important;
  --color: var(--ion-color-medium-tint);
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.3
  }
  20% {
    opacity: 0.5;
  }
  30% {
    opacity: 0.85;
  }
  40% {
    opacity: 0.9;
  }
  60% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
