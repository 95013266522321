.microsoft-signin {
    display: inline-block;
    background: white;
    color: #444;
    width: 208px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
    &:active {
        opacity: .7;
    }

    &-icon {
        background: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Ctitle%3EMS-SymbolLockup%3C/title%3E%3Crect x='1' y='1' width='9' height='9' fill='%23f25022'/%3E%3Crect x='1' y='11' width='9' height='9' fill='%2300a4ef'/%3E%3Crect x='11' y='1' width='9' height='9' fill='%237fba00'/%3E%3Crect x='11' y='11' width='9' height='9' fill='%23ffb900'/%3E%3C/svg%3E") transparent 0px 50% no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }

    &-text {
        display: inline-block;
        vertical-align: middle;
        padding-left: 0px;
        padding-right: 8px;
        font-size: 14px;
        font-weight: bold;
        /* Use the Roboto font that is loaded in the <head> */
        font-family: 'Roboto', sans-serif;
        color: var(--ion-color-medium-shade);
    }
}