.monitor-content {
    display: flex;

    form {
        padding: 15px;
        min-width: 300px;
        max-width: 600px;
        margin: auto;
    }

    ion-header {
        padding: 10px 0px 10px 0px;
        border: none;

        &::after {
            background-image: unset;
        }
    }

    .company-item {
        ion-icon {
            cursor: pointer;
        }
    }

    ion-grid {
        margin: 0px 15px 0px 15px;
        font-size: .8em !important;
    }

    ion-item {
        ion-label {
            font-size: .8em !important;
            width: 100px;
            flex: 0 0 auto;
        }

        ion-input,
        ion-select {
            font-size: .8em;
            background-color: var(--ion-color-light-tint);
            padding: 5px;

            .native-input {
                padding: 0px;
                margin: 5px;
            }
        }

        ion-checkbox {
            align-content: flex-start;
            flex: 0 0 auto;
            margin: 0px;
        }
    }
    ion-alert {
        --min-width: 500px !important;
    }    

    .agent-row {
        ion-col:nth-child(2) {
            &:hover,&:active {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
