.chart-container {
    height: 100%;
    display: flex;
    &-selector {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 15px;
        ion-select {
            margin: auto;
        }
        ion-button {
            z-index: 1000;
            position: absolute;
            top: -8px;
            right: 0px;
        }
    }
    .no-data-label {
        margin: auto;
        width: 100%;
        text-align: center;
        align-self: center;
    }
}