.profile-content {
  display: flex;

  form {
    padding: 15px;
    min-width: 300px;
    max-width: 600px;
    margin: auto;
  }

  ion-header {
    padding: 10px 0px 10px 0px;
    border: none;
    &::after {
      background-image: unset;
    }
    .sub-header {
      font-size: 1.1em;
    }
  }

  .company-item {
    ion-button {
      text-transform: none;      
      font-size: .9em;
      font-weight: normal;
      &:hover,&:active {
        text-decoration: underline;
      }
    }
    ion-icon {
      cursor: pointer;
    }
  }

  ion-item {    
    ion-label {
      font-size: .8em !important;
      width: 100px;
    }

    ion-input,
    ion-select {
      font-size: .8em;
      background-color: var(--ion-color-light-tint);

      .native-input {
        padding: 0px;
        margin: 5px;
      }
    }
  }
}