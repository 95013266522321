.solar-icon {
    width: 32px;
    height: 32px;
    padding: 10px;
    svg {
        color: var(--ion-color-medium);
    }
}

.menu-expandable {
    flex-direction: column;
    cursor: pointer;
}

.menu-submenu {
    padding-left: 20px;
}

ion-menu-button {
    color: var(--ion-color-medium-tint)
}

.version-label {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    vertical-align: bottom;
    font-size: .7em;
    opacity: .4;
    position: absolute;
    bottom: 0px;
    div {
        align-self: flex-end;
        flex: 1;
        margin-bottom: 8px;
    }
    .poweredby-darksky {
        flex: 1;
        height: 40px;
    }
}
