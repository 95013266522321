ion-grid {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  ion-row {
    width: 100%;
    ion-col {
      padding-bottom: 0px;
    }
  }
}

.gauge-container {
  border: 0.5px inset var(--ion-color-light-tint);
  margin: 0px;
  padding: 5px;
  text-align: center;
  height: 100%;
  ion-card-header {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px 0px 10px 0px;
    ion-card-subtitle {
      color: var(--ion-color-medium-tint);
    }
    ion-icon {
      animation: opacity 5s ease-in-out infinite;
      opacity: 1;    
    }
  }
  ion-card-content {
    padding: 0px;
  }
  .item-footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: .8em;
    color: var(--ion-color-medium-tint);

    &-row1, &-row2 {
      display: flex;
      flex-direction: row;
    }

    div {
      flex-direction: row;
      white-space: nowrap;
    }

    &-col0,
    &-col4 {
      flex: 1;
    }

    &-col1 {
      padding-right: 5px;
      text-align: right;
      flex: 0;
    }

    &-col2 {
      padding-left: 5px;
      padding-right: 5px;
      text-align: right;
      flex: 0;
    }

    &-col3 {
      padding-left: 5px;
      text-align: center;
      flex: 0;
    }
    
    &-date {
      padding-top: 5px;
      padding-bottom: 0px;
      margin: 0px auto;
    }
  }
}

.no-data {
  animation: opacity 5s ease-in-out infinite;
  opacity: 1;    
  color: var(--ion-color-warning);
}

.react-swipeable-view-container {
  position: absolute;
  top: 0px;
  bottom: 24px;
  width: 100%;  
  > div {
    overflow: hidden !important;
  }
}
.carousel-pager {
  position: absolute;
  bottom: 0px;
  height: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  svg {
    cursor: pointer;
  }
}
